<template>
	<div class="row patient_chart add_local_files">
		<div class="col-lg-6 col-xl-5 edit_record_page">
			<div>
				<div class="box-body containt_detail mt-30">
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Template Description<small
								class="asterisksign">*</small></label>
						<div class="col-sm-7">
							<input type="text" v-model="form.title" class="form-control">
							<ValidationErrorMessageList :errors="v$.form.title.$errors" />
						</div>
					</div>
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Module<small
								class="asterisksign">*</small></label>
						<div class="col-sm-7">
							<div class="moduleList_dropdown">
								<CustomDropDown :options="moduleList" :initialValue="form.module" :fieldName="`module`"
									@item-selected="handleItemSelected" v-model="form.module" :refs="`module`">
								</CustomDropDown>
							</div>
							<ValidationErrorMessageList :errors="v$.form.module.$errors" />
						</div>
					</div>
					<div class="row pd-bottom pb-0">
						<label for="text" class="col-sm-5 col-form-label right-bor">Notes</label>
						<div class="col-sm-7"><input type="text" class="form-control" v-model="form.additional_info">
						</div>
					</div>
				</div>
				<p class="signature-poplated-line text-end mt-40 mb-50" v-if="selectedOption == 'signature'">Signature
					is populated
					based on the user of access (Doctor / Nurse only)</p>
				<div class="box-body containt_detail mt-30"
					v-if="selectedTextBoxId !== null && selectedOption === 'textbox' && Object.keys(selectedTextBox).length > 0 && currentPage === selectedTextBox.onPage">
					<!-- <div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">
							Text Box Name
							<small class="asterisksign">*</small>
						</label>
						<div class="col-sm-7">
							<input type="text" v-model="selectedTextBox.text_box_name" class="form-control">
						</div>
					</div> -->
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">
							Database Tag
							<small class="asterisksign">*</small>
						</label>
						<div class="col-sm-7">
							<div class="database_tag_drop_box position-relative">
								<div class="input_box w-100">
									<input type="text" class="form-control form-control-lg"
										v-model="selectedTextBox.database_tag" @keyup="tagSearch"
										:readonly="selectedTextBox.isDatabaseTagReadOnly" required>
								</div>
								<div class="search_drop_box" v-if="filteredTags.length > 0">
									<div v-for="(tag, index) in filteredTags" :key="index" class="con_drop_line"
										@click="selectTag(tag)">
										<div class="man_haeding mb-0">
											<span>{{ tag.category }} | <span class="fw-300">{{ tag.label
													}}</span></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="or-devider-line position-relative text-center z-index-1 mb-20">
						<span>OR</span>
					</div>
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Plain Text</label>
						<div class="col-sm-7">
							<input type="text" class="form-control" v-model="selectedTextBox.placeholder"
								:readonly="selectedTextBox.isPlainTextReadOnly">
						</div>
					</div>
				</div>

				<div class="box-body containt_detail mt-30"
					v-if="(selectedDropDownId !== null && selectedOption == 'dropdown' && Object.keys(selectedDropDown).length > 0) && (currentPage == selectedDropDown.onPage)">
					<!-- <div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Dropdown Name<small
								class="asterisksign">*</small></label>
						<div class="col-sm-7"><input type="text" v-model="selectedDropDown.dropdown_box_name"
								class="form-control">
						</div>
					</div> -->
					<div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Dropdown Options<small
								class="asterisksign">*</small></label>
						<div class="col-sm-7">
							<input type="text" v-model="selectedDropDown.placeholder" class="form-control">
						</div>
					</div>
					<span class="comma-separate-tags d-block mb-0 mr-20 text-end">use comma to separate dropdown options
						ex: 1, 2, 3</span>
				</div>

				<div class="div-button-container text-end mt-30">
					<span class="add-page" @click="addBlankPage">Add Blank Page</span>
					<span class="delete-element" @click="deleteElement"
						:disabled="selectedElement === '' && selectedElementId === null">Delete Element</span>
				</div>
			</div>

			<div class="btn_part text-center" style="margin-top: 130px;">
				<button class="comman_brdr_btn big_btn mx30" @click="backToList">Cancel</button>
				<button class="comman_btn big_btn mx30" @click="uploadDocument">Save <img src="images/loader.gif"
						v-if="this.isShowLoader" /></button>
			</div>
		</div>
		<div class="col-lg-6 col-xl-7 pdf-view">
			<!-- <div class="slide_deatil_box" v-if="documentPath!=null">
				<ejs-pdfviewer 
					id="pdfViewer" 
					ref="pdfviewer" 
					:documentPath="documentPath" 
					:resourceUrl="resourceUrl"
					@documentLoad="onDocumentLoad"
					:enableBookmark="false"
					:openFile='false'
					@beforeTooltipRender="beforeTooltipRender"
					:enableToolbar="true"
					:enableNavigationToolbar="false"
				>
				</ejs-pdfviewer>
			</div> -->
			<div class="t-pdf-edit-button text-center" v-if="documentPath">
				<button id="addTextBoxButton" class="comman_brdr_btn btn mb-10 ms-0 ml-50 fs-18"
					:class="{ active: selectedOption === 'textbox' }" @click="addTextBox">
					Text Box
				</button>
				<button id="addDropdownButton" class="comman_brdr_btn btn mb-10 ml-50 fs-18"
					:class="{ active: selectedOption === 'dropdown' }" @click="addDropDown">
					Dropdown
				</button>
				<button id="addImageButton" class="comman_brdr_btn btn" 
					:class="{ active: selectedOption === 'image' }"
					@click="addImage">
					Image
				</button>
				<button id="addSignatureButton" class="comman_brdr_btn btn"
					:class="{ active: selectedOption === 'signature' }" @click="addSignature">
					Signature
				</button>
				<button id="addCheckboxButton" class="comman_brdr_btn btn" 
					:class="{ active: selectedOption === 'checkbox' }"
					@click="addCheckbox">
					Check Box
				</button>
			</div>
			<div class="slide_deatil_box pdf-show-part overflow-auto" ref="pdfContainer" v-if="documentPath != null">
				<div class="text-center position-relative" v-if="this.documentPath" style="width: 675px; height: 792px; margin: 0 auto;">
					<div class="pdf-view">
						<div v-if="showContextMenu" :style="{ top: `${contextMenuY}px`, left: `${contextMenuX}px` }"
							class="context-menu">
							<ul>
								<li v-if="isContextShowFromCanvas" @click="pasteElement">Paste</li>
								<template v-else>
									<li @click="copyElement">Copy</li>
									<li @click="cutElement">Cut</li>
									<li @click="pasteElement(e)">Paste</li>
									<li @click="deleteElement">Delete</li>
								</template>
							</ul>
						</div>
						<div v-for="textBox in allTextBoxes" :key="textBox.id">
							<div class="text-box" :ref="`textBox-${textBox.id}`" v-if="textBox.isShowTextBox" :style="{
								left: `${textBox.x_loc}px`,
								top: `${textBox.y_loc}px`,
								width: `${textBox.width}px`,
								height: `${textBox.height}px`,
								fontSize: (textBox.fontSize) + 'px',
								padding: 0 + 'px',
								display: textBox.onPage === currentPage ? 'block' : 'none'
							}" @click="onClickSelectElement($event, textBox.id, 'textbox')" @contextmenu="handleElementRightClick">
								<div class="resize-handle top-left"
									@mousedown="startResize($event, textBox.id, 'top-left', 'textbox')"></div>
								<div class="resize-handle top-right"
									@mousedown="startResize($event, textBox.id, 'top-right', 'textbox')"></div>
								<div class="resize-handle bottom-left"
									@mousedown="startResize($event, textBox.id, 'bottom-left', 'textbox')"></div>
								<div class="resize-handle bottom-right"
									@mousedown="startResize($event, textBox.id, 'bottom-right', 'textbox')"></div>
								<textarea class="text-box-content" :ref="`textarea-${textBox.id}`" :value="this.wrappedTextBoxVal[textBox.id]" :style="{
										cursor: 'move',
										boxSizing: 'border-box',
										padding: '0px',
									}" @mousedown="dragHandle($event, textBox.id, 'textbox')" readonly></textarea>
							</div>
						</div>
						<div v-for="dropDown in allDropDowns" :key="dropDown.id">
							<div class="pdf-dropdown-drag" :ref="`dropDown-${dropDown.id}`" v-if="dropDown.isShowDropDown" :style="{
								left: `${dropDown.x_loc}px`,
								top: `${dropDown.y_loc}px`,
								width: `${dropDown.width}px`,
								height: `${dropDown.height}px`,
								fontSize: (dropDown.fontSize) + 'px',
								display: dropDown.onPage === currentPage ? 'block' : 'none'
							}" @click="onClickSelectElement($event, dropDown.id, 'dropdown')" @contextmenu="handleElementRightClick">
								<div class="resize-handle top-left"
									@mousedown="startResize($event, dropDown.id, 'top-left', 'dropdown')"></div>
								<div class="resize-handle top-right"
									@mousedown="startResize($event, dropDown.id, 'top-right', 'dropdown')"></div>
								<div class="resize-handle bottom-left"
									@mousedown="startResize($event, dropDown.id, 'bottom-left', 'dropdown')"></div>
								<div class="resize-handle bottom-right"
									@mousedown="startResize($event, dropDown.id, 'bottom-right', 'dropdown')"></div>
								<div class="name-selcet-w-pdf d-flex justify-content-center align-items-center h-100 position-relative" 
									@mousedown="dragHandle($event, dropDown.id, 'dropdown')" style="cursor: move;">
									<div class="custom-select position-relative" @click="toggleDropdown(dropDown.id)">
										<div class="select-display d-flex justify-content-center align-items-center"
											:style="{
												height: `${dropDown.height}px`,
												width: `${dropDown.width}px`,
											}">
											<span class="selected-list-actv" :style="{
												fontSize: (dropDown.fontSize) + 'px',
											}"></span>
											<img src="/images/down-arrow-new.svg" class="arrow open rotate180">
										</div>
										<ul class="select-options" v-if="dropDown.isOpen">
											<li v-for="option in dropDown.database_tag" :key="option"
												class="is-selected" :value="option" :style="{
												fontSize: (dropDown.fontSize) + 'px'}">
												{{ option }}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div v-for="imageBox in allImages" :key="imageBox.id">
							<div class="image-box" :ref="`imageBox-${imageBox.id}`" v-if="imageBox.isShowImageBox" :style="{
								left: `${imageBox.x_loc}px`,
								top: `${imageBox.y_loc}px`,
								width: `${imageBox.width}px`,
								height: `${imageBox.height}px`,
								display: imageBox.onPage === currentPage ? 'block' : 'none'
							}" @click="onClickSelectElement($event, imageBox.id, 'image')" @contextmenu="handleElementRightClick">
								<div class="resize-handle top-left"
									@mousedown="startResize($event, imageBox.id, 'top-left', 'image')"></div>
								<div class="resize-handle top-right"
									@mousedown="startResize($event, imageBox.id, 'top-right', 'image')"></div>
								<div class="resize-handle bottom-left"
									@mousedown="startResize($event, imageBox.id, 'bottom-left', 'image')"></div>
								<div class="resize-handle bottom-right"
									@mousedown="startResize($event, imageBox.id, 'bottom-right', 'image')"></div>
								<img :src="imageBox.placeholder" @mousedown="dragHandle($event, imageBox.id, 'image')" :style="{
									cursor: 'move',
									boxSizing: 'border-box',
									width: (imageBox.width - 3) + 'px',
									height: (imageBox.height - 3) + 'px',
								}" >
							</div>
						</div>
						<div v-for="signatureBox in allSignatures" :key="signatureBox.id">
							<div class="signature-box" :ref="`signatureBox-${signatureBox.id}`" v-if="signatureBox.isShowSignatureBox" :style="{
								left: `${signatureBox.x_loc}px`,
								top: `${signatureBox.y_loc}px`,
								width: `${signatureBox.width}px`,
								height: `${signatureBox.height}px`,
								display: signatureBox.onPage === currentPage ? 'block' : 'none'
							}" @click="onClickSelectElement($event, signatureBox.id, 'signature')" @contextmenu="handleElementRightClick">
								<div class="resize-handle top-left"
									@mousedown="startResize($event, signatureBox.id, 'top-left', 'signature')"></div>
								<div class="resize-handle top-right"
									@mousedown="startResize($event, signatureBox.id, 'top-right', 'signature')"></div>
								<div class="resize-handle bottom-left"
									@mousedown="startResize($event, signatureBox.id, 'bottom-left', 'signature')"></div>
								<div class="resize-handle bottom-right"
									@mousedown="startResize($event, signatureBox.id, 'bottom-right', 'signature')">
								</div>
								<img class="signature-image" @mousedown="dragHandle($event, signatureBox.id, 'signature')" :style="{
									cursor: 'move',
									width: '100%',
									height: '100%',
									position: 'relative',
									background: '#fff',
								}" />
							</div>
						</div>
						<div v-for="checkBox in allCheckBoxes" :key="checkBox.id">
							<div class="checkbox-box" :ref="`checkBox-${checkBox.id}`" v-if="checkBox.isShowCheckBox" :style="{
								left: checkBox.x_loc + 'px',
								top: checkBox.y_loc + 'px',
								width: (checkBox.width) + 'px',
								height: (checkBox.height) + 'px',
								padding: 0 + 'px',
								display: checkBox.onPage === currentPage ? 'block' : 'none'
							}" @click="onClickSelectElement($event, checkBox.id, 'checkbox')" @contextmenu="handleElementRightClick">
								<div class="resize-handle top-left"
									@mousedown="startResize($event, checkBox.id, 'top-left', 'checkbox')"></div>
								<div class="resize-handle top-right"
									@mousedown="startResize($event, checkBox.id, 'top-right', 'checkbox')"></div>
								<div class="resize-handle bottom-left"
									@mousedown="startResize($event, checkBox.id, 'bottom-left', 'checkbox')"></div>
								<div class="resize-handle bottom-right"
									@mousedown="startResize($event, checkBox.id, 'bottom-right', 'checkbox')"></div>
								<div class="pdf-checkbox" @mousedown="dragHandle($event, checkBox.id, 'checkbox')">
									<input type="checkbox" :checked="checkBox.placeholder" @click="toggleCheckbox"
										class="form-check-input m-0" :style="{
											cursor: 'move',
											height: (checkBox.height - 3) + 'px',
											width: (checkBox.width - 3) + 'px',
											border: '0px !important',
											padding: '0px'
										}" />
								</div>
							</div>
						</div>
					</div>
					<canvas id="pdfCanvas" ref="pdfCanvas" style="width: 100%; height: 100%;" @contextmenu="handleCanvasRightClick" ></canvas>
				</div>
				<div class="pdf-pagination-sticky" v-if="this.documentPath && isShowPagination">
					<div class="query-pagination text-center" v-if="totalPages > 1">
						<ul class="page-pagination p-0 mt-0">
							<li style="margin-right: 40px;">
								<img src="/images/left-arrow-pagen.svg" class="img-fluid" v-if="currentPage > 1"
									@click="goToPreviousPage">
							</li>
							<li v-if="totalPages > 1 || currentPage === 1">{{ currentPage }}</li>
							<li v-if="totalPages > 1">/</li>
							<li v-if="totalPages > 1 || currentPage === 1">{{ totalPages }}</li>
							<li style="margin-left: 40px;">
								<img src="/images/right-arrow-pagen.svg" class="img-fluid"
									v-if="currentPage < totalPages" @click="goToNextPage">
							</li>
						</ul>
					</div>
					<div v-else class="text-center">
						1/1
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CustomDropDown from '../../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
import { databaseTags } from '../../../databaseTags.js';
import $ from "jquery";
// import {
// 	Toolbar, Magnification, Navigation, LinkAnnotation,
// 	BookmarkView, ThumbnailView, Print, TextSelection, TextSearch,
// 	Annotation, FormDesigner, FormFields
// } from '@syncfusion/ej2-vue-pdfviewer';
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { PDFDocument } from 'pdf-lib';
import ValidationErrorMessageList from '../../base/ValidationErrorMessageList.vue';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			isOpen: false,
			form: {
				title: null,
				additional_info: null,
				module: null,
				dynamicFields: [],
				annotations: '',
				annotatedPdfBytes: new Uint8Array(),
			},
			moduleList: [
				{ value: "Consultation", title: "Consultation" },
				{ value: "Imaging", title: "Imaging" },
				{ value: "Lab", title: "Lab" },
				{ value: "Others", title: "Others" },
			],
			resourceUrl: "https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib",
			documentPath: null,

			pdfDoc: null,
			existingPdfBytes: null,
			totalPages: 0,
			currentPage: 1,
			selectedOption: '',

			selectedElement: '',
			selectedElementId: null,
			rightClickedElement: null,

			/* Textbox Props */
			nextTextBoxId: 0,
			showTagsList: false,
			filteredTags: [],
			databaseTags,
			allTextBoxes: [],
			selectedTextBox: {},
			selectedTextBoxId: null,
			wrappedTextBoxVal: [],

			/* Dropdown Props */
			nextDropDownId: 0,
			allDropDowns: [],
			selectedDropDown: {},
			selectedDropDownId: null,

			/* Image Props */
			nextImageBoxId: 0,
			allImages: [],
			selectedImageBox: {},
			selectedImageBoxId: null,

			/* Signature Props */
			nextSignatureBoxId: 0,
			allSignatures: [],
			selectedSignatureBox: {},
			selectedSignatureBoxId: null,

			/* CheckBox Props */
			nextCheckBoxId: 0,
			allCheckBoxes: [],
			selectedCheckBox: {},
			selectedCheckBoxId: null,

			isShowLoader: false,
			isShowPagination: false,

			showContextMenu: false,
			isContextShowFromCanvas: false,
			contextMenuX: 0,
			contextMenuY: 0,

			clipboardElement: null,
			copiedElement: {},

			minFontSize: 8,
		}
	},
	components: {
		CustomDropDown,
		ValidationErrorMessageList
	},
	validations() {
		return {
			form: {
				title: {
					required: helpers.withMessage("Please enter document title.", required),
				},
				module: {
					required: helpers.withMessage("Please select the module.", required),
				},
			},
		};
	},
	methods: {
		async edit() {
			this.$store.state.loader = true;
			axios.post("e-forms/retrieve", { 'id': this.$route.params.template_id })
				.then((response) => {
					if (response.data.status === 200) {
						this.form.title = response.data.data.title;
						this.form.additional_info = response.data.data.additional_info;
						this.form.module = response.data.data.module;
						this.documentPath = this.documentUrl(response.data.data.file_path);
						if (this.documentPath) {
							this.modifyPdf();
						}
						this.dynamicFields = response.data.data.formFields;

					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					this.$filters.moshaToast(error.message, "error");
				});
		},
		documentUrl(documentPath) {
			// Assuming your documents are stored in the 'public' directory
			return `${process.env.VUE_APP_STORAGE_URL}/e-forms/document/view/${documentPath}`;
		},
		async modifyPdf() {
			try {
				if (!this.documentPath) {
					throw new Error('Document path is not defined.');
				}

				this.form.annotatedPdfBytes = await fetch(this.documentPath).then(res => res.arrayBuffer());
				this.existingPdfBytes = this.form.annotatedPdfBytes;
				this.pdfDoc = await PDFDocument.load(this.existingPdfBytes);
				await this.pdfDoc.save();

				this.totalPages = this.pdfDoc.getPageCount();
				if(this.totalPages > 0)
				{
					this.isShowPagination = true;
				}
				this.renderPage(this.currentPage);
			} catch (error) {
				console.error('Error modifying PDF:', error);
			}
		},
		async renderPage(pageNumber, from = '') {
			try {
				await this.$nextTick();

				const canvas = this.$refs.pdfCanvas;
				if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
					// await this.retryGetCanvas();
					throw new Error('Canvas element is not found or is not a valid HTMLCanvasElement.');
				}

				const context = canvas.getContext('2d');
				if (!context) {
					throw new Error('Unable to get 2D context of canvas.');
				}

				const loadingTask = window.pdfjsLib.getDocument({ data: this.existingPdfBytes });
				const pdf = await loadingTask.promise;
				const page = await pdf.getPage(pageNumber);

				const viewport = page.getViewport({ scale: 1 });
				canvas.width = viewport.width;
				canvas.height = viewport.height;

				const renderContext = {
					canvasContext: context,
					viewport: viewport,
				};
				await page.render(renderContext).promise;

				if (from != "next" && from != "previous" && from != "add-page") {
					await this.renderEditAnnotation();
				}
				this.$store.state.loader = false;

				if(!this.copiedElement) {
					this.resetSelectedElementObjAndId();
				}
			} catch (error) {
				console.error('Error rendering page:', error);
			}
		},
		async renderEditAnnotation() {
			for (const key in this.dynamicFields) {
				if (Object.prototype.hasOwnProperty.call(this.dynamicFields, key)) {
					const annotation = this.dynamicFields[key];

					annotation.id = key;
					if (key.includes('textBox')) {
						await this.addTextBox(null, annotation);
					} else if (key.includes('dropDown')) {
						await this.addDropDown(null, annotation);
					} else if (key.includes('image')) {
						await this.addImage(null, annotation);
					} else if (key.includes('signature')) {
						await this.addSignature(null, annotation);
					} else if (key.includes('checkBox')) {
						await this.addCheckbox(null, annotation);
					} else {
						console.warn(`Unknown key type in annotation: ${key}`);
					}
				}
			}
		},
		resetSelectedElementObjAndId() {
			this.selectedTextBox = {};
			this.selectedTextBoxId = null;
			this.selectedDropDown = {};
			this.selectedDropDownId = null;
			this.selectedImageBox = {};
			this.selectedImageBoxId = null;
			this.selectedSignatureBox = {};
			this.selectedSignatureBoxId = null;
			this.selectedCheckBox = {};
			this.selectedCheckBoxId = null;
			this.selectedElement = '';
			this.selectedElementId = null;
		},
		async addBlankPage() {
			if (!this.pdfDoc) {
				alert('PDF document is not initialized.');
				return;
			}

			this.selectedOption = '';
			this.selectedElement = '';
			this.selectedElementId = null;

			// const [firstPage] = await this.pdfDoc.getPages();
			// const { width, height } = firstPage.getSize();
			// this.pdfDoc.insertPage(this.currentPage++, [width, height]);
			
			const lastPageIndex = await this.pdfDoc.getPageCount() - 1;
			const lastPage = this.pdfDoc.getPage(lastPageIndex);
			const { width, height } = lastPage.getSize();

			this.pdfDoc.insertPage(lastPageIndex + 1, [width, height]);

			this.totalPages = this.pdfDoc.getPageCount();
			// this.currentPage = Math.min(this.currentPage, this.totalPages);
			this.currentPage = lastPageIndex + 2;

			this.existingPdfBytes = await this.pdfDoc.save();

			await this.renderPage(this.currentPage, 'add-page');
		},
		goToNextPage() {
			if (this.currentPage < this.totalPages) {
				this.currentPage++;
				this.renderPage(this.currentPage, 'next');
			}
		},
		goToPreviousPage() {
			if (this.currentPage > 1) {
				this.currentPage--;
				this.renderPage(this.currentPage, 'previous');
			}
		},
		// validateTextBoxes() {
		// 	let isValid = true;

		// 	if (Array.isArray(this.allTextBoxes) && this.allTextBoxes.length > 0) {
		// 		for (let textBox of this.allTextBoxes) {
		// 			console.log('!textBox.database_tag || !textBox.placeholder')
		// 			if (!textBox.database_tag && !textBox.placeholder) {
		// 				this.$filters.moshaToast(`Either 'database tag' or 'plain Text' is required for the text box ${textBox.id}.`, "error");
		// 				isValid = false;
		// 				break;
		// 			}

		// 			if (!textBox.text_box_name) {
		// 				this.$filters.moshaToast(`The 'text box name' is required for the text box ${textBox.id}.`, "error");
		// 				isValid = false;
		// 				break;
		// 			}
		// 		}
		// 	}
		// 	return isValid;
		// },
		validateDropdowns() {
			let isValid = true;

			if (Array.isArray(this.allDropDowns) && this.allDropDowns.length > 0) {
				for (let dropDown of this.allDropDowns) {
					if (!dropDown.dropdown_box_name) {
						this.$filters.moshaToast(`The name is required for the dropdown.`, "error");
						isValid = false;
						break;
					}

					if (!dropDown.database_tag) {
						this.$filters.moshaToast(`Options are required for the dropdown.`, "error");
						isValid = false;
						break;
					}
				}
			}
			return isValid;
		},
		validateAll() {
			// const isTextBoxesValid = this.validateTextBoxes();
			// return isTextBoxesValid && isDropdownsValid;
			const isDropdownsValid = this.validateDropdowns();
			return isDropdownsValid;
		},
		onClickSelectElement(event, elementId, elementType) {
			event.preventDefault();
			
			if (elementType === 'textbox') {
				this.selectedOption = "textbox";
				const textBox = this.allTextBoxes.find(tb => tb.id === elementId);
				this.selectedTextBoxId = elementId;
				this.selectedTextBox = textBox;
				this.selectedElement = "textbox";
				this.selectedElementId = elementId;
			} else if (elementType === 'dropdown') {
				this.selectedOption = "dropdown";
				const dropDown = this.allDropDowns.find(dw => dw.id === elementId);
				this.selectedDropDownId = elementId;
				this.selectedDropDown = dropDown;
				this.selectedElement = "dropdown";
				this.selectedElementId = elementId;
			} else if (elementType === 'image') {
				this.selectedOption = "image";
				const imageBox = this.allImages.find(im => im.id === elementId);
				this.selectedImageBoxId = elementId;
				this.selectedImageBox = imageBox;
				this.selectedElement = "image";
				this.selectedElementId = elementId;
			} else if (elementType === 'signature') {
				this.selectedOption = "signature";
				const signatureBox = this.allSignatures.find(sb => sb.id === elementId);
				this.selectedSignatureBoxId = elementId;
				this.selectedSignatureBox = signatureBox;
				this.selectedElement = "signature";
				this.selectedElementId = elementId;
			} else if (elementType === 'checkbox') {
				this.selectedOption = "checkbox";
				const checkBox = this.allCheckBoxes.find(cb => cb.id === elementId);
				this.selectedCheckBoxId = elementId;
				this.selectedCheckBox = checkBox;
				this.selectedElement = "checkbox";
				this.selectedElementId = elementId;
			}
		},
		handleCanvasRightClick(event) {
			event.preventDefault();

			const canvas = this.$refs.pdfCanvas;
			const canvasRect = canvas.getBoundingClientRect();

			this.isContextShowFromCanvas = true;
			if (event.button === 2) {
				this.contextMenuX = event.clientX - canvasRect.left;
				this.contextMenuY = event.clientY - canvasRect.top;
				this.showContextMenu = true;
				document.addEventListener('click', this.hideContextMenu);
			} else {
				this.showContextMenu = false;
			}
		},
		handleElementRightClick(event) {
			event.preventDefault();

			this.rightClickedElement = event.target;
			const canvas = this.$refs.pdfCanvas;
			const canvasRect = canvas.getBoundingClientRect();

			this.showContextMenu = true;
			if (event.button === 2) {
				this.contextMenuX = event.clientX - canvasRect.left;
				this.contextMenuY = event.clientY - canvasRect.top;

				document.addEventListener('click', this.hideContextMenu);
			} else {
				this.showContextMenu = false;
			}
		},
		hideContextMenu() {
			this.showContextMenu = false;
			this.isContextShowFromCanvas = false;
			document.removeEventListener('click', this.hideContextMenu);
		},
		handleKeydown(event) {
			// Check if the event is a key event
			if (event.type === 'keydown') {
				// let refName, ref, element, elementRefs;

				// switch (this.selectedElement) {
				// 	case 'textbox':
				// 		refName = `textBox-${this.selectedElementId}`;
				// 		break;
				// 	case 'dropdown':
				// 		refName = `dropDown-${this.selectedElementId}`;
				// 		break;
				// 	case 'image':
				// 		refName = `imageBox-${this.selectedElementId}`;
				// 		break;
				// 	case 'signature':
				// 		refName = `signatureBox-${this.selectedElementId}`;
				// 		break;
				// 	case 'checkbox':
				// 		refName = `checkBox-${this.selectedElementId}`;
				// 		break;
				// 	default:
				// 		refName = null;
				// 		break;
				// }

				// If refName is found, get the corresponding element reference
				// if (refName) {
				// 	ref = this.$refs[refName];
				// 	element = ref?.$el || ref;
				// 	elementRefs = Array.isArray(element) ? element[0] : element;
				// }

				// Check if the right-clicked element is the element itself or a child of it
				// if (event.key === 'Backspace' && this.selectedElementId != null) {
				// 	if (this.rightClickedElement === elementRefs || elementRefs?.contains(this.rightClickedElement)) {
				// 		this.deleteElement();
				// 		this.showContextMenu = false;
				// 	}
				// }
				if (event.key === 'Backspace') {
					this.deleteElement();
					this.showContextMenu = false;
				}
			}
		},
		copyElement() {
			if (this.selectedElementId !== null && this.selectedElementId !== undefined && this.selectedElement != "") {
				switch (this.selectedElement) {
					case 'textbox':
						this.copiedElement = this.allTextBoxes.find(textBox => textBox.id === this.selectedElementId);
						break;
					case 'dropdown':
						this.copiedElement = this.allDropDowns.find(dropDown => dropDown.id === this.selectedElementId);
						break;
					case 'image':
						this.copiedElement = this.allImages.find(image => image.id === this.selectedElementId);
						break;
					case 'signature':
						this.copiedElement = this.allSignatures.find(signature => signature.id === this.selectedElementId);
						break;
					case 'checkbox':
						this.copiedElement = this.allCheckBoxes.find(checkbox => checkbox.id === this.selectedElementId);
						break;
					default:
						console.error("Unknown element type:", this.selectedElement);
						break;
				}
			} else {
				console.warn("No element selected to copy.");
			}

			this.hideContextMenu();
		},
		pasteElement(event) {
			event.preventDefault();

			if (!this.copiedElement || this.selectedElementId == null) {
				console.log("No element copied or cut to paste.");
				return;
			}

			if (this.copiedElement && Object.keys(this.copiedElement).length != 0) {
				const newElement = {
					...this.copiedElement,
					
					x_loc: this.contextMenuX,
					y_loc: this.contextMenuY,
					onPage: this.currentPage
				};

				switch (this.selectedElement) {
					case 'textbox':
						newElement.id = this.nextTextBoxId++;
						newElement.text_box_name = `TextBox ${this.nextTextBoxId}`,
						newElement.isShowTextBox = true,
						newElement.isShowTextBoxFields = true,
						this.wrappedTextBoxVal[newElement.id] = this.wrappedTextBoxVal[this.selectedElementId];
						this.allTextBoxes.push(newElement);
						break;
					case 'dropdown':
						newElement.id = this.nextDropDownId++;
						newElement.dropdown_box_name = 'DropDown - ' + this.nextDropDownId,
						newElement.isShowDropDown = true,
						newElement.isShowDropDownFields = true,
						this.allDropDowns.push(newElement);
						break;
					case 'image':
						newElement.id = this.nextImageBoxId++;
						newElement.isShowImageBox = true;
						this.allImages.push(newElement);
						break;
					case 'signature':
						newElement.id = this.nextSignatureBoxId++;
						newElement.isShowSignatureBox = true;
						this.allSignatures.push(newElement);
						break;
					case 'checkbox':
						newElement.id = this.nextCheckBoxId++;
						newElement.isShowCheckBox = true;
						this.allCheckBoxes.push(newElement);
						break;
					default:
						console.error("Unknown element type:", this.selectedElement);
						break;
				}
			} else {
				switch (this.selectedElement) {
					case 'textbox': {
						const index = this.allTextBoxes.findIndex(el => el.id === this.selectedElementId);
						if (index !== -1) {
							this.allTextBoxes[index].isShowTextBox = true;
							this.allTextBoxes[index].x_loc = this.contextMenuX;
							this.allTextBoxes[index].y_loc = this.contextMenuY;
							this.allTextBoxes[index].onPage = this.currentPage;
						}
						break;
					}
					case 'dropdown': {
						const index = this.allDropDowns.findIndex(el => el.id === this.selectedElementId);
						if (index !== -1) {
							this.allDropDowns[index].isShowDropDown = true;
							this.allDropDowns[index].x_loc = this.contextMenuX;
							this.allDropDowns[index].y_loc = this.contextMenuY;
							this.allDropDowns[index].onPage = this.currentPage;
						}
						break;
					}
					case 'image': {
						const index = this.allImages.findIndex(el => el.id === this.selectedElementId);
						if (index !== -1) {
							this.allImages[index].isShowImageBox = true;
							this.allImages[index].x_loc = this.contextMenuX;
							this.allImages[index].y_loc = this.contextMenuY;
							this.allImages[index].onPage = this.currentPage;
						}
						break;
					}
					case 'signature': {
						const index = this.allSignatures.findIndex(el => el.id === this.selectedElementId);
						if (index !== -1) {
							this.allSignatures[index].isShowSignatureBox = true;
							this.allSignatures[index].x_loc = this.contextMenuX;
							this.allSignatures[index].y_loc = this.contextMenuY;
							this.allSignatures[index].onPage = this.currentPage;
						}
						break;
					}
					case 'checkbox': {
						const index = this.allCheckBoxes.findIndex(el => el.id === this.selectedElementId);
						if (index !== -1) {
							this.allCheckBoxes[index].isShowCheckBox = true;
							this.allCheckBoxes[index].x_loc = this.contextMenuX;
							this.allCheckBoxes[index].y_loc = this.contextMenuY;
							this.allCheckBoxes[index].onPage = this.currentPage;
						}
						break;
					}
					default:
						console.error("Unknown element type:", this.selectedElement);
						break;
				}
			}
		},
		cutElement() {
			this.copiedElement = {};
			if (!this.selectedElementId || !this.selectedElement) {
				console.warn("No element selected to cut.");
				return;
			}

			switch (this.selectedElement) {
				case 'textbox': {
					const index = this.allTextBoxes.findIndex(el => el.id === this.selectedElementId);
					if (index !== -1) {
						this.allTextBoxes[index].isShowTextBox = false;
					} else {
						console.warn("Textbox not found with ID:", this.selectedElementId);
					}
					break;
				}
				case 'dropdown': {
					const index = this.allDropDowns.findIndex(el => el.id === this.selectedElementId);
					if (index !== -1) {
						this.allDropDowns[index].isShowDropDown = false;
					} else {
						console.warn("Dropdown not found with ID:", this.selectedElementId);
					}
					break;
				}
				case 'image': {
					const index = this.allImages.findIndex(el => el.id === this.selectedElementId);
					if (index !== -1) {
						this.allImages[index].isShowImageBox = false;
					} else {
						console.warn("Image not found with ID:", this.selectedElementId);
					}
					break;
				}
				case 'signature': {
					const index = this.allSignatures.findIndex(el => el.id === this.selectedElementId);
					if (index !== -1) {
						this.allSignatures[index].isShowSignatureBox = false;
					} else {
						console.warn("Signature not found with ID:", this.selectedElementId);
					}
					break;
				}
				case 'checkbox': {
					const index = this.allCheckBoxes.findIndex(el => el.id === this.selectedElementId);
					if (index !== -1) {
						this.allCheckBoxes[index].isShowCheckBox = false;
					} else {
						console.warn("Checkbox not found with ID:", this.selectedElementId);
					}
					break;
				}
				default:
					console.error("Unknown element type:", this.selectedElement);
					return;
			}
		},
		deleteElement() {
			if (this.selectedElement == 'textbox' && this.selectedElementId != null) {
				const index = this.allTextBoxes.findIndex(tb => tb.id === this.selectedElementId);
				if (index !== -1) {
					this.allTextBoxes.splice(index, 1);
					this.wrappedTextBoxVal.splice(index, 1);

					for (let i = index; i < this.allTextBoxes.length; i++) {
						this.allTextBoxes[i].id -= 1;
					}

					if (this.nextTextBoxId > 1) {
						this.nextTextBoxId -= 1;
					}
					this.selectedOption = '';
					this.resetTextBoxProperties();
				}
			} else if (this.selectedElement == 'dropdown' && this.selectedElementId != null) {
				const index = this.allDropDowns.findIndex(ddw => ddw.id === this.selectedElementId);
				if (index !== -1) {
					this.allDropDowns.splice(index, 1);
					for (let i = index; i < this.allDropDowns.length; i++) {
						this.allDropDowns[i].id -= 1;
					}

					if (this.nextDropDownId > 1) {
						this.nextDropDownId -= 1;
					}
					this.selectedOption = '';
					this.resetDropdownProperties();
				}
			} else if (this.selectedElement == 'image' && this.selectedElementId != null) {
				const index = this.allImages.findIndex(im => im.id === this.selectedElementId);
				if (index !== -1) {
					this.allImages.splice(index, 1);
					for (let i = index; i < this.allImages.length; i++) {
						this.allImages[i].id -= 1;
					}

					if (this.nextImageBoxId > 1) {
						this.nextImageBoxId -= 1;
					}
					this.selectedOption = '';
					this.resetImageProperties();
				}
			} else if (this.selectedElement == 'signature' && this.selectedElementId != null) {
				const index = this.allSignatures.findIndex(sb => sb.id === this.selectedElementId);
				if (index !== -1) {
					this.allSignatures.splice(index, 1);
					for (let i = index; i < this.allSignatures.length; i++) {
						this.allSignatures[i].id -= 1;
					}

					if (this.nextSignatureBoxId > 1) {
						this.nextSignatureBoxId -= 1;
					}
					this.selectedOption = '';
					this.resetSignatureProperties();
				}
			} else if (this.selectedElement == 'checkbox' && this.selectedElementId != null) {
				const index = this.allCheckBoxes.findIndex(cb => cb.id === this.selectedElementId);
				if (index !== -1) {
					this.allCheckBoxes.splice(index, 1);
					for (let i = index; i < this.allCheckBoxes.length; i++) {
						this.allCheckBoxes[i].id -= 1;
					}

					if (this.nextCheckBoxId > 1) {
						this.nextCheckBoxId -= 1;
					}
					this.selectedOption = '';
					this.resetCheckboxProperties();
				}
			}
		},
		resetTextBoxProperties() {
			this.selectedElement = '';
			this.selectedElementId = null;

			this.selectedTextBox = {};
			this.selectedTextBoxId = null;

			this.showTagsList = false;
			this.filteredTags = [];
		},
		resetDropdownProperties() {
			this.selectedElement = '';
			this.selectedElementId = null;

			this.selectedDropDown = {};
			this.selectedDropDownId = null;
		},
		resetImageProperties() {
			this.selectedElement = '';
			this.selectedElementId = null;

			this.selectedImageBox = {};
			this.selectedImageBoxId = null;
		},
		resetSignatureProperties() {
			this.selectedElement = '';
			this.selectedElementId = null;

			this.selectedSignatureBox = {};
			this.selectedSignatureBoxId = null;
		},
		resetCheckboxProperties() {
			this.selectedElement = '';
			this.selectedElementId = null;

			this.selectedCheckBox = {};
			this.selectedCheckBoxId = null;
		},
		getElementCenter(top, bottom, left, right, elementWidth, elementHeight) {
			// Calculate horizontal center
			const centerX = ((right - left) / 2) - (elementWidth / 2);
			
			// Calculate vertical center
			const centerY = ((bottom - top) / 2) - (elementHeight / 2);
			
			// Return the center coordinates
			return { centerX, centerY };
		},
		calculateFontSize(maxWidth, maxHeight) {
			let fontSize = Math.min(maxWidth / 10, maxHeight / 2);
			const minFontSize = this.minFontSize || 8;

			if (fontSize < minFontSize) {
				fontSize = minFontSize;
			}

			return fontSize;
		},
		handleItemSelected(obj) {
			if (obj.fieldName === 'dropdown_name') {
				const dropdownIndex = this.allDropDowns.findIndex(
					(dropdown) => dropdown.id === this.selectedDropDownId
				);
				if (dropdownIndex !== -1) {
					this.allDropDowns[dropdownIndex].selectedValue = obj.item.value;
				}
			}
			else {
				this.form.module = obj.item.value;
			}
		},
		async retryGetCanvas(retryCount = 5) {
			for (let i = 0; i < retryCount; i++) {
				await this.$nextTick();
				const canvas = this.$refs.pdfCanvas;
				if (canvas && canvas instanceof HTMLCanvasElement) {
					return;
				}
				console.warn(`Retrying to get canvas element (${i + 1}/${retryCount})...`);
				await new Promise(resolve => setTimeout(resolve, 100));
			}
			throw new Error('Failed to retrieve canvas element after multiple attempts.');
		},
		async addTextBox(event = null, annotation = {}) {
			if (event) {
				this.resetTextBoxProperties();
			}

			const isAnnotationPresent = Object.keys(annotation).length > 0;
			this.selectedOption = isAnnotationPresent ? '' : 'textbox';
			const textboxCurrentPage = isAnnotationPresent ? annotation.pageNum : this.currentPage;
			
			let newX, newY, newWidth, newHeight, isTagReadOnly, isTextReadOnly;
			if(!isAnnotationPresent) {
				newWidth = 120;
				newHeight = 40;

				const canvas = this.$refs.pdfCanvas;
				if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
					throw new Error('At AddTextBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
				}

				const canvasRect = canvas.getBoundingClientRect();

				const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(canvasRect.top, canvasRect.bottom, canvasRect.left, canvasRect.right, newWidth, newHeight);
				newX = leftOffset;
				newY = topOffset;

				isTagReadOnly = false;
				isTextReadOnly = false;
			} else {
				isTagReadOnly = annotation.database_tag ? false : true;
				isTextReadOnly = annotation.placeholder ? false : true;
			}
			const newTextBox = {
				id: this.nextTextBoxId++,
				isShowTextBox: true,
				isShowTextBoxFields: isAnnotationPresent ? false : true,
				isDragging: false,
				isResizing: false,
				isPlainTextReadOnly: isTextReadOnly,
				isDatabaseTagReadOnly: isTagReadOnly,
				x_loc: isAnnotationPresent ? annotation.x_loc : newX,
				y_loc: isAnnotationPresent ? annotation.y_loc : newY,
				width: isAnnotationPresent ? annotation.width : newWidth,
				height: isAnnotationPresent ? annotation.height : newHeight,
				fontSize: isAnnotationPresent ? this.calculateFontSize(annotation.width, annotation.height, 'textBox') : this.calculateFontSize(newWidth, newHeight, 'textBox'),
				database_tag: isAnnotationPresent ? annotation.database_tag : '',
				placeholder: isAnnotationPresent ? annotation.placeholder : '',
				onPage: textboxCurrentPage,
				text_box_name: isAnnotationPresent ? annotation.field_type : `TextBox ${this.nextTextBoxId}`,
				wrap_text_content: '',
				position: {
					clientX: undefined,
					clientY: undefined,
					dragStartX: 0,
					dragStartY: 0,
					resizeStartX: 0,
					resizeStartY: 0,
					boxStartLeft: 0,
					boxStartTop: 0,
					boxStartWidth: 0,
					boxStartHeight: 0,
					resizeDirection: '',
				}
			};

			
			let result = {};
			if(newTextBox.database_tag) {
				result = await this.wrapText(newTextBox.database_tag, newTextBox.width, newTextBox.height, newTextBox.fontSize);

				newTextBox.wrap_text_content = result.wrappedText;
				newTextBox.fontSize = result.fontSize;
				this.wrappedTextBoxVal[newTextBox.id] = result.wrappedText;

			} else if (newTextBox.placeholder) {
				result = await this.wrapText(newTextBox.placeholder, newTextBox.width, newTextBox.height, newTextBox.fontSize);

				newTextBox.wrap_text_content = result.wrappedText;
				newTextBox.fontSize = result.fontSize;
				this.wrappedTextBoxVal[newTextBox.id] = result.wrappedText;
			} else {
				this.wrappedTextBoxVal[newTextBox.id] = '';
			}
			this.selectedTextBox = newTextBox;
			this.selectedTextBoxId = newTextBox.id;
			this.selectedElement = 'textbox';
			this.selectedElementId = newTextBox.id;
			this.allTextBoxes.push(newTextBox);
			this.$nextTick(() => {
				console.log('New TextBox - Style:- ', `left: ${newTextBox.x_loc}px`, `top: ${newTextBox.y_loc}px`, newTextBox);
			});
		},
		async addDropDown(event = null, annotation = {}) {
			if (event) {
				this.resetDropdownProperties();
			}

			const isAnnotationPresent = Object.keys(annotation).length > 0;

			this.selectedOption = isAnnotationPresent ? '' : 'dropdown';
			const dropdownCurrentPage = isAnnotationPresent ? annotation.pageNum : this.currentPage;

			this.$nextTick(() => {
				let newX, newY, newWidth, newHeight;

				if(!isAnnotationPresent) {
					newWidth = 120;
					newHeight = 40;

					const canvas = this.$refs.pdfCanvas;
					if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
						throw new Error('At AddTextBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
					}

					const canvasRect = canvas.getBoundingClientRect();

					const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(canvasRect.top, canvasRect.bottom, canvasRect.left, canvasRect.right, newWidth, newHeight);
					newX = leftOffset;
					newY = topOffset;
				}

				const newDropDown = {
					id: this.nextDropDownId++,
					isShowDropDown: true,
					isShowDropDownFields: isAnnotationPresent ? false : true,
					isOpen: false,
					isDragging: true,
					isResizing: true,
					dropdown_box_name: isAnnotationPresent ? annotation.field_type : 'DropDown - ' + this.nextDropDownId,
					database_tag: isAnnotationPresent ? annotation.database_tag : '',
					placeholder: isAnnotationPresent ? annotation.placeholder : '',
					fontSize: isAnnotationPresent ? this.calculateFontSize(annotation.width, annotation.height, 'dropDown') : this.calculateFontSize(newWidth, newHeight, 'dropDown'),
					position: {
						clientX: undefined,
						clientY: undefined,
						dragStartX: 0,
						dragStartY: 0,
						boxStartTop: 0,
						boxStartLeft: 0,
						resizeStartX: 0,
						resizeStartY: 0,
						boxStartWidth: 0,
						boxStartHeight: 0,
						resizeDirection: '',
					},
					x_loc: isAnnotationPresent ? annotation.x_loc : newX,
					y_loc: isAnnotationPresent ? annotation.y_loc : newY,
					width: isAnnotationPresent ? annotation.width : 120,
					height: isAnnotationPresent ? annotation.height : 40,
					onPage: dropdownCurrentPage,
				};

				this.selectedDropDown = newDropDown;
				this.selectedDropDownId = newDropDown.id;
				this.selectedElement = 'dropdown';
				this.selectedElementId = newDropDown.id;
				this.allDropDowns.push(newDropDown);
				this.$nextTick(() => {
					console.log('New Dropdown - Style:- ', `left: ${newDropDown.x_loc}`, `top: ${newDropDown.y_loc}`, newDropDown);
				});
			});
		},
		addImage(_, annotation = {}) {

			this.selectedOption = 'image';
			const isAnnotationPresent = Object.keys(annotation).length > 0;

			if (isAnnotationPresent) {
				this.dynamicImageUpload(annotation);
			} else {
				const input = document.createElement('input');
				input.type = 'file';
				input.accept = 'image/*';
				input.addEventListener('change', this.handleImageUpload);
				input.click();
			}
		},
		handleImageUpload(event) {
			this.resetImageProperties();

			const file = event.target.files[0];
			if (file) {
				const reader = new FileReader();
				reader.onload = (e) => {
					const canvas = this.$refs.pdfCanvas;
					if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
						throw new Error('At ImageBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
					}
					const canvasRect = canvas.getBoundingClientRect();

					const newWidth = 120;
					const newHeight = 120;
					const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(canvasRect.top, canvasRect.bottom, canvasRect.left, canvasRect.right, newWidth, newHeight);

					const newX = leftOffset;
					const newY = topOffset;

					this.selectedOption = 'image';

					const newImageBox = {
						id: this.nextImageBoxId++,
						isShowImageBox: true,
						isDragging: false,
						isResizing: false,
						position: {
							clientX: undefined,
							clientY: undefined,
							dragStartX: 0,
							dragStartY: 0,
							boxStartTop: 0,
							boxStartLeft: 0,
							resizeStartX: 0,
							resizeStartY: 0,
							boxStartWidth: 0,
							boxStartHeight: 0,
							resizeDirection: '',
						},
						imageResizeStart: {
							width: 0,
							height: 0,
							mouseX: 0,
							mouseY: 0,
						},
						placeholder: '',
						x_loc: newX,
						y_loc: newY,
						width: newWidth,
						height: newHeight,
						onPage: this.currentPage,
					};

					this.selectedImageBox = newImageBox;
					this.selectedImageBoxId = newImageBox.id;
					this.selectedElement = 'image';
					this.selectedElementId = newImageBox.id;
					this.allImages.push(newImageBox);

					newImageBox.placeholder = e.target.result;
					this.$nextTick(() => {
						this.$nextTick(() => {
							console.log(`ImageBox x_loc: ${newImageBox.x_loc}, y_loc: ${newImageBox.y_loc}, Width: ${newImageBox.width}, Height: ${newImageBox.height}`, newImageBox);
						});
					});
				};
				reader.readAsDataURL(file);
			}
		},
		dynamicImageUpload(annotation) {
			this.selectedOption = 'image';
			const imageBoxCurrentPage = annotation ? annotation.pageNum : this.currentPage;

			const newImageBox = {
				id: this.nextImageBoxId++,
				isShowImageBox: true,
				isDragging: false,
				isResizing: false,
				position: {
					clientX: undefined,
					clientY: undefined,
					dragStartX: 0,
					dragStartY: 0,
					boxStartTop: 0,
					boxStartLeft: 0,
					resizeStartX: 0,
					resizeStartY: 0,
					boxStartWidth: 0,
					boxStartHeight: 0,
					resizeDirection: '',
				},
				imageResizeStart: {
					width: 0,
					height: 0,
					mouseX: 0,
					mouseY: 0,
				},
				placeholder: annotation.placeholder,
				x_loc: annotation.x_loc,
				y_loc: annotation.y_loc,
				width: annotation.width,
				height: annotation.height,
				onPage: imageBoxCurrentPage,
			};

			this.selectedImageBox = newImageBox;
			this.selectedImageBoxId = newImageBox.id;
			this.selectedElement = 'image';
			this.selectedElementId = newImageBox.id;
			this.allImages.push(newImageBox);
			this.$nextTick(() => {
				console.log(`dynamic ImageBox - X: ${newImageBox.x_loc}, Y: ${newImageBox.y_loc}, Width: ${newImageBox.width}, Height: ${newImageBox.height}`, newImageBox);
			});
		},
		async addSignature(_, annotation = {}) {
			this.resetSignatureProperties();
			try {
				const isAnnotationPresent = Object.keys(annotation).length > 0;

				this.selectedOption = 'signature';
				const signatureBoxCurrentPage = isAnnotationPresent ? annotation.pageNum : this.currentPage;

				let newX, newY, newWidth, newHeight;
				if(!isAnnotationPresent) {
					newWidth = 120;
					newHeight = 40;

					const canvas = this.$refs.pdfCanvas;
					if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
						throw new Error('At AddTextBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
					}

					const canvasRect = canvas.getBoundingClientRect();

					const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(canvasRect.top, canvasRect.bottom, canvasRect.left, canvasRect.right, newWidth, newHeight);
					newX = leftOffset;
					newY = topOffset;
				}

				// const authUserRole = localStorage.getItem('authUserRole');
				// const clinicId = localStorage.getItem('clinicId');
				const userId = isAnnotationPresent ? annotation.database_tag : localStorage.getItem('userId');

				// if (!authUserRole || !clinicId || !userId) {
				// 	throw new Error('Missing required parameters.');
				// }

				// if (authUserRole === 'Doctor' || authUserRole === 'Nurse') {
					// this.$store.state.loader = true;
					// const response = await axios.post('user/get-signature', { clinicId, userId });

					// let signatureSrc = response.data.data.base64_sign;
					// // Ensure the signatureSrc has the correct format
					// if (!signatureSrc.startsWith('data:image/png;base64,')) {
					// 	console.error('Invalid base64 signature format');
					// 	return;
					// }
					// let signatureSrc = '';
					const signatureBox = {
						id: this.nextSignatureBoxId++,
						isShowSignatureBox: true,
						isDragging: false,
						isResizing: false,
						position: {
							clientX: undefined,
							clientY: undefined,
							dragStartX: 0,
							dragStartY: 0,
							resizeStartX: 0,
							resizeStartY: 0,
							boxStartLeft: 0,
							boxStartTop: 0,
							boxStartWidth: 0,
							boxStartHeight: 0,
							resizeDirection: '',
						},
						database_tag : userId,
						// src: signatureSrc,
						x_loc: isAnnotationPresent? annotation.x_loc : newX,
						y_loc: isAnnotationPresent? annotation.y_loc : newY,
						width: isAnnotationPresent? annotation.width : newWidth,
						height: isAnnotationPresent? annotation.height : newHeight,
						onPage: isAnnotationPresent ? annotation.pageNum : signatureBoxCurrentPage,
					};

					this.selectedSignatureBox = signatureBox;
					this.selectedSignatureBoxId = signatureBox.id;
					this.selectedElement = 'signature';
					this.selectedElementId = signatureBox.id;
					this.allSignatures.push(signatureBox);
					this.$nextTick(() => {
						// this.$store.state.loader = false;
						console.log('New signatures - Style:- ', `left: ${signatureBox.x_loc}px`, `top: ${signatureBox.y_loc}px`, signatureBox);
					});
				// } else {
				// 	console.log('User role is not Doctor or Nurse. No signature requested.');
				// }
			} catch (error) {
				console.error('Error getting signature:', error);
			}
		},
		async addCheckbox(_, annotation = {}) {
			this.selectedCheckBox = {};
			this.selectedCheckBoxId = null;
			this.selectedElement = '';
			this.selectedElementId = null;

			const isAnnotationPresent = Object.keys(annotation).length > 0;

			this.selectedOption = 'checkbox';
			const checkBoxCurrentPage = isAnnotationPresent ? annotation.pageNum : this.currentPage;

			const canvas = this.$refs.pdfCanvas;
			if (!canvas || !(canvas instanceof HTMLCanvasElement)) {
				throw new Error('At AddTextBox - Canvas element is not found or is not a valid HTMLCanvasElement.');
			}
			const canvasRect = canvas.getBoundingClientRect();

			let newX, newY, newWidth, newHeight;
			
			// if(isAnnotationPresent) {
			// 	newX = annotation.ele_x_loc;
			// 	newY = annotation.ele_y_loc;
			// 	newWidth = annotation.ele_width;
			// 	newHeight = annotation.ele_height;
			// } else {
			// 	newWidth = 60;
			// 	newHeight = 60;
			// 	const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(canvasRect.top, canvasRect.bottom, canvasRect.left, canvasRect.right, newWidth, newHeight);
			// 	newX = canvasRect.left + leftOffset;
			// 	newY = canvasRect.top + topOffset;
			// }

			if(!isAnnotationPresent) {
				newWidth = 30;
				newHeight = 30;
				const { centerX: leftOffset, centerY: topOffset } = this.getElementCenter(canvasRect.top, canvasRect.bottom, canvasRect.left, canvasRect.right, newWidth, newHeight);
				newX = leftOffset;
				newY = topOffset;
			}

			const newCheckBox = {
				id: this.nextCheckBoxId++,
				isShowCheckBox: true,
				isDragging: false,
				isResizing: false,
				position: {
					clientX: undefined,
					clientY: undefined,
					dragStartX: 0,
					dragStartY: 0,
					resizeStartX: 0,
					resizeStartY: 0,
					boxStartLeft: 0,
					boxStartTop: 0,
					boxStartWidth: 0,
					boxStartHeight: 0,
					resizeDirection: '',
				},
				x_loc: isAnnotationPresent ? annotation.x_loc : newX,
				y_loc: isAnnotationPresent ? annotation.y_loc : newY,
				width: isAnnotationPresent ? annotation.width : newWidth,
				height: isAnnotationPresent ? annotation.height : newHeight,
				placeholder: false,
				onPage: isAnnotationPresent ? annotation.pageNum : checkBoxCurrentPage,
				isShowOnCurrentPage: this.currentPage === checkBoxCurrentPage,
			};

			this.selectedCheckBox = newCheckBox;
			this.selectedCheckBoxId = newCheckBox.id;
			this.selectedElement = 'checkbox';
			this.selectedElementId = newCheckBox.id;
			this.allCheckBoxes.push(newCheckBox);
			this.$nextTick(() => {
				console.log('New newCheckBox - Style:- ', `left: ${newCheckBox.x_loc}px`, `top: ${newCheckBox.y_loc}px`, newCheckBox);
			});
		},
		dragHandle(event, elementId, elementType) {
			event.preventDefault();
			if (elementType === 'textbox') {
				this.selectedOption = 'textbox';
				const textBox = this.allTextBoxes.find(tb => tb.id === elementId);
				this.selectedTextBoxId = elementId;
				this.selectedTextBox = textBox;
				this.selectedElement = 'textbox';
				this.selectedElementId = elementId;
				if (textBox) {
					textBox.isDragging = true;
					textBox.position.clientX = event.clientX;
					textBox.position.clientY = event.clientY;
					textBox.position.dragStartX = event.clientX;
					textBox.position.dragStartY = event.clientY;
					textBox.position.boxStartLeft = textBox.x_loc;
					textBox.position.boxStartTop = textBox.y_loc;
					document.addEventListener('mousemove', (e) => this.handleMouseMove(textBox, e, elementType));
					document.addEventListener('mouseup', () => this.handleMouseUp(textBox, elementType));
				}
			} else if (elementType === 'dropdown') {
				this.selectedOption = 'dropdown';
				const dropDown = this.allDropDowns.find(dw => dw.id === elementId);
				this.selectedDropDownId = elementId;
				this.selectedDropDown = dropDown;
				this.selectedElement = 'dropdown';
				this.selectedElementId = elementId;

				if (dropDown) {
					dropDown.isDragging = true;
					dropDown.position.clientX = event.clientX;
					dropDown.position.clientY = event.clientY;
					dropDown.position.dragStartX = event.clientX
					dropDown.position.dragStartY = event.clientY
					dropDown.position.boxStartLeft = dropDown.x_loc;
					dropDown.position.boxStartTop = dropDown.y_loc;
					document.addEventListener('mousemove', (e) => this.handleMouseMove(dropDown, e, elementType));
					document.addEventListener('mouseup', () => this.handleMouseUp(dropDown, elementType));
				}
			} else if (elementType === 'image') {
				this.selectedOption = 'image';
				const imageBox = this.allImages.find(im => im.id === elementId);
				this.selectedImageBoxId = elementId;
				this.selectedImageBox = imageBox;
				this.selectedElement = 'image';
				this.selectedElementId = elementId;

				if (imageBox) {
					imageBox.isDragging = true;
					imageBox.position.clientX = event.clientX;
					imageBox.position.clientY = event.clientY;
					imageBox.position.dragStartX = event.clientX;
					imageBox.position.dragStartY = event.clientY;
					imageBox.position.boxStartLeft = imageBox.x_loc;
					imageBox.position.boxStartTop = imageBox.y_loc;
					document.addEventListener('mousemove', (e) => this.handleMouseMove(imageBox, e, elementType));
					document.addEventListener('mouseup', () => this.handleMouseUp(imageBox, elementType));
				}
			} else if (elementType === 'signature') {
				this.selectedOption = 'signature';
				const signatureBox = this.allSignatures.find(sb => sb.id === elementId);
				this.selectedSignatureBoxId = elementId;
				this.selectedSignatureBox = signatureBox;
				this.selectedElement = 'signature';
				this.selectedElementId = elementId;

				if (signatureBox) {
					signatureBox.isDragging = true;
					signatureBox.position.clientX = event.clientX;
					signatureBox.position.clientY = event.clientY;
					signatureBox.position.dragStartX = event.clientX;
					signatureBox.position.dragStartY = event.clientY;
					signatureBox.position.boxStartLeft = signatureBox.x_loc;
					signatureBox.position.boxStartTop = signatureBox.y_loc;
					document.addEventListener('mousemove', (e) => this.handleMouseMove(signatureBox, e, elementType));
					document.addEventListener('mouseup', () => this.handleMouseUp(signatureBox, elementType));
				}
			} else if (elementType === 'checkbox') {
				this.selectedOption = 'checkbox';
				const checkBox = this.allCheckBoxes.find(cb => cb.id === elementId);
				this.selectedCheckBoxId = elementId;
				this.selectedCheckBox = checkBox;
				this.selectedElement = 'checkbox';
				this.selectedElementId = elementId;

				if (checkBox) {
					checkBox.isDragging = true;
					checkBox.position.clientX = event.clientX;
					checkBox.position.clientY = event.clientY;
					checkBox.position.dragStartX = event.clientX;
					checkBox.position.dragStartY = event.clientY;
					checkBox.position.boxStartLeft = checkBox.x_loc;
					checkBox.position.boxStartTop = checkBox.y_loc;
					document.addEventListener('mousemove', (e) => this.handleMouseMove(checkBox, e, elementType));
					document.addEventListener('mouseup', () => this.handleMouseUp(checkBox, elementType));
				}
			}
		},
		handleMouseMove(getElement, event, elementType) {
			event.preventDefault();

			const canvas = this.$refs.pdfCanvas;
			const canvasRect = canvas.getBoundingClientRect();

			// Function to apply boundary constraints
			const applyBoundaryConstraints = (x, y, width, height) => {
				let constrainedX = Math.max(0, Math.min(x, canvasRect.width - width));
				let constrainedY = Math.max(0, Math.min(y, canvasRect.height - height));
				return { constrainedX, constrainedY };
			};

			if (elementType === "textbox") {
				const textBox = getElement;
				if (!textBox.isDragging) return;
				
				let newX = event.clientX - canvasRect.left - (textBox.width / 2);
				let newY = event.clientY - canvasRect.top - (textBox.height / 2);

				// Apply boundary constraints
				const { constrainedX, constrainedY } = applyBoundaryConstraints(newX, newY, textBox.width, textBox.height);

				textBox.x_loc = constrainedX;
				textBox.y_loc = constrainedY;

				this.updateElementPosition(textBox, elementType);
			} else if (elementType === "dropdown") {
				const dropDown = getElement;
				if (!dropDown.isDragging) return;

				// const dx = event.clientX - dropDown.position.dragStartX;
				// const dy = event.clientY - dropDown.position.dragStartY;

				// let newX = dropDown.position.boxStartLeft + dx;
				// let newY = dropDown.position.boxStartTop + dy;

				let newX = event.clientX - canvasRect.left - (dropDown.width / 2);
				let newY = event.clientY - canvasRect.top - (dropDown.height / 2);

				const { constrainedX, constrainedY } = applyBoundaryConstraints(newX, newY, dropDown.width, dropDown.height);

				dropDown.x_loc = constrainedX;
				dropDown.y_loc = constrainedY;

				this.updateElementPosition(dropDown, elementType);
			} else if (elementType === "image") {
				const imageBox = getElement;
				if (!imageBox.isDragging) return;

				const dx = event.clientX - imageBox.position.dragStartX;
				const dy = event.clientY - imageBox.position.dragStartY;

				let newX = imageBox.position.boxStartLeft + dx;
				let newY = imageBox.position.boxStartTop + dy;

				const { constrainedX, constrainedY } = applyBoundaryConstraints(newX, newY, imageBox.width, imageBox.height);

				imageBox.x_loc = constrainedX;
				imageBox.y_loc = constrainedY;

				this.updateElementPosition(imageBox, elementType);
			} else if (elementType === "signature") {
				const signatureBox = getElement;
				if (!signatureBox.isDragging) return;

				const dx = event.clientX - signatureBox.position.dragStartX;
				const dy = event.clientY - signatureBox.position.dragStartY;

				let newX = signatureBox.position.boxStartLeft + dx;
				let newY = signatureBox.position.boxStartTop + dy;

				const { constrainedX, constrainedY } = applyBoundaryConstraints(newX, newY, signatureBox.width, signatureBox.height);

				signatureBox.x_loc = constrainedX;
				signatureBox.y_loc = constrainedY;

				this.updateElementPosition(signatureBox, elementType);
			} else if (elementType === "checkbox") {
				const checkBox = getElement;
				if (!checkBox.isDragging) return;

				const dx = event.clientX - checkBox.position.dragStartX;
				const dy = event.clientY - checkBox.position.dragStartY;

				let newX = checkBox.position.boxStartLeft + dx;
				let newY = checkBox.position.boxStartTop + dy;

				const { constrainedX, constrainedY } = applyBoundaryConstraints(newX, newY, checkBox.width, checkBox.height);

				checkBox.x_loc = constrainedX;
				checkBox.y_loc = constrainedY;

				this.updateElementPosition(checkBox, elementType);
			}
		},
		handleMouseUp(element) {
			if (!element.isDragging) return;
			element.isDragging = false;
			document.removeEventListener('mousemove', (e) => this.handleMouseMove(element, e));
			document.removeEventListener('mouseup', () => this.handleMouseUp(element));
		},
		startResize(event, elementId, direction, elementType) {
			event.preventDefault();
			if (elementType === 'textbox') {
				this.selectedOption = 'textbox';
				const textBox = this.allTextBoxes.find(tb => tb.id === elementId);
				this.selectedTextBoxId = elementId;
				this.selectedTextBox = textBox;
				this.selectedElement = 'textbox';
				this.selectedElementId = elementId;
				if (!textBox) return;

				textBox.isResizing = true;
				textBox.position.resizeDirection = direction;

				textBox.position.resizeStartX = event.clientX;
				textBox.position.resizeStartY = event.clientY;
				textBox.position.boxStartWidth = textBox.width;
				textBox.position.boxStartHeight = textBox.height;
				textBox.position.boxStartLeft = textBox.x_loc;
				textBox.position.boxStartTop = textBox.y_loc;

				this.allTextBoxes = this.allTextBoxes.map(tb =>
					tb.id === elementId ? { ...textBox } : tb
				);

				this.currentResizeMouseMoveHandler = (e) => this.resizeMouseMove(textBox, e, elementType);
				this.currentResizeMouseUpHandler = (e) => this.resizeMouseUp(textBox, e, elementType);

				document.addEventListener('mousemove', this.currentResizeMouseMoveHandler);
				document.addEventListener('mouseup', this.currentResizeMouseUpHandler);
			} else if (elementType === 'dropdown') {
				this.selectedOption = 'dropdown';
				const dropDown = this.allDropDowns.find(ddw => ddw.id === elementId);
				this.selectedDropDownId = elementId;
				this.selectedDropDown = dropDown;
				this.selectedElement = 'dropDown';
				this.selectedElementId = elementId;
				if (!dropDown) return;

				dropDown.isResizing = true;
				dropDown.position.resizeDirection = direction;

				dropDown.position.resizeStartX = event.clientX;
				dropDown.position.resizeStartY = event.clientY;
				dropDown.position.boxStartWidth = dropDown.width;
				dropDown.position.boxStartHeight = dropDown.height;
				dropDown.position.boxStartLeft = dropDown.x_loc;
				dropDown.position.boxStartTop = dropDown.y_loc;

				this.allDropDowns = this.allDropDowns.map(ddw =>
					ddw.id === elementId ? { ...dropDown } : ddw
				);

				this.currentResizeMouseMoveHandler = (e) => this.resizeMouseMove(dropDown, e, elementType);
				this.currentResizeMouseUpHandler = (e) => this.resizeMouseUp(dropDown, e, elementType);

				document.addEventListener('mousemove', this.currentResizeMouseMoveHandler);
				document.addEventListener('mouseup', this.currentResizeMouseUpHandler);
			} else if (elementType === 'image') {
				this.selectedOption = 'image';
				const imageBox = this.allImages.find(im => im.id === elementId);
				this.selectedImageBoxId = elementId;
				this.selectedImageBox = imageBox;
				this.selectedElement = 'image';
				this.selectedElementId = elementId;
				if (!imageBox) return;

				imageBox.isResizing = true;
				imageBox.position.resizeDirection = direction;

				imageBox.position.resizeStartX = event.clientX;
				imageBox.position.resizeStartY = event.clientY;
				imageBox.position.boxStartWidth = imageBox.width;
				imageBox.position.boxStartHeight = imageBox.height;
				imageBox.position.boxStartLeft = imageBox.x_loc;
				imageBox.position.boxStartTop = imageBox.y_loc;

				this.allImages = this.allImages.map(im =>
					im.id === elementId ? { ...imageBox } : im
				);

				this.currentResizeMouseMoveHandler = (e) => this.resizeMouseMove(imageBox, e, elementType);
				this.currentResizeMouseUpHandler = (e) => this.resizeMouseUp(imageBox, e, elementType);

				document.addEventListener('mousemove', this.currentResizeMouseMoveHandler);
				document.addEventListener('mouseup', this.currentResizeMouseUpHandler);
			} else if (elementType === 'signature') {
				this.selectedOption = 'signature';
				const signatureBox = this.allSignatures.find(isb => isb.id === elementId);
				this.selectedSignatureBoxId = elementId;
				this.selectedSignatureBox = signatureBox;
				this.selectedElement = 'signature';
				this.selectedElementId = elementId;
				if (!signatureBox) return;

				signatureBox.isResizing = true;
				signatureBox.position.resizeDirection = direction;

				signatureBox.position.resizeStartX = event.clientX;
				signatureBox.position.resizeStartY = event.clientY;
				signatureBox.position.boxStartWidth = signatureBox.width;
				signatureBox.position.boxStartHeight = signatureBox.height;
				signatureBox.position.boxStartLeft = signatureBox.x_loc;
				signatureBox.position.boxStartTop = signatureBox.y_loc;

				this.allSignatures = this.allSignatures.map(im =>
					im.id === elementId ? { ...signatureBox } : im
				);

				this.currentResizeMouseMoveHandler = (e) => this.resizeMouseMove(signatureBox, e, elementType);
				this.currentResizeMouseUpHandler = (e) => this.resizeMouseUp(signatureBox, e, elementType);

				document.addEventListener('mousemove', this.currentResizeMouseMoveHandler);
				document.addEventListener('mouseup', this.currentResizeMouseUpHandler);
			} else if (elementType === 'checkbox') {
				this.selectedOption = 'checkbox';
				const checkBox = this.allCheckBoxes.find(cb => cb.id === elementId);
				this.selectedCheckBoxBoxId = elementId;
				this.selectedCheckBox = checkBox;
				this.selectedElement = 'checkbox';
				this.selectedElementId = elementId;
				if (!checkBox) return;

				checkBox.isResizing = true;
				checkBox.position.resizeDirection = direction;

				checkBox.position.resizeStartX = event.clientX;
				checkBox.position.resizeStartY = event.clientY;
				checkBox.position.boxStartWidth = checkBox.width;
				checkBox.position.boxStartHeight = checkBox.height;
				checkBox.position.boxStartLeft = checkBox.x_loc;
				checkBox.position.boxStartTop = checkBox.y_loc;

				this.allCheckBoxes = this.allCheckBoxes.map(im =>
					im.id === elementId ? { ...checkBox } : im
				);

				this.currentResizeMouseMoveHandler = (e) => this.resizeMouseMove(checkBox, e, elementType);
				this.currentResizeMouseUpHandler = (e) => this.resizeMouseUp(checkBox, e, elementType);

				document.addEventListener('mousemove', this.currentResizeMouseMoveHandler);
				document.addEventListener('mouseup', this.currentResizeMouseUpHandler);
			}
		},
		applyBoundaryConstraints(x, y, width, height, canvasRect) {
			// let constrainedX = Math.max(canvasRect.left, Math.min(x, canvasRect.right - width));
			// let constrainedY = Math.max(canvasRect.top, Math.min(y, canvasRect.bottom - height));
			// return { constrainedX, constrainedY };
			const constrainedX = Math.max(0, Math.min(x, canvasRect.width - width));
			const constrainedY = Math.max(0, Math.min(y, canvasRect.height - height));
			return { constrainedX, constrainedY };
		},	
		resizeMouseMove(getElement, event, elementType) {
			if (getElement.isResizing) {
				const canvas = this.$refs.pdfCanvas;
				const canvasRect = canvas.getBoundingClientRect();

				const deltaX = event.clientX - getElement.position.resizeStartX;
				const deltaY = event.clientY - getElement.position.resizeStartY;

				let newWidth, newHeight, newLeft, newTop;

				switch (getElement.position.resizeDirection) {
					case 'top-left':
						newWidth = getElement.position.boxStartWidth - deltaX;
						newHeight = getElement.position.boxStartHeight - deltaY;
						newLeft = getElement.position.boxStartLeft + deltaX;
						newTop = getElement.position.boxStartTop + deltaY;
						break;
					case 'top-right':
						newWidth = getElement.position.boxStartWidth + deltaX;
						newHeight = getElement.position.boxStartHeight - deltaY;
						newLeft = getElement.position.boxStartLeft;
						newTop = getElement.position.boxStartTop + deltaY;
						break;
					case 'bottom-left':
						newWidth = getElement.position.boxStartWidth - deltaX;
						newHeight = getElement.position.boxStartHeight + deltaY;
						newLeft = getElement.position.boxStartLeft + deltaX;
						newTop = getElement.position.boxStartTop;
						break;
					case 'bottom-right':
						newWidth = getElement.position.boxStartWidth + deltaX;
						newHeight = getElement.position.boxStartHeight + deltaY;
						newLeft = getElement.position.boxStartLeft;
						newTop = getElement.position.boxStartTop;
						break;
					default:
						return;
				}

				// Apply boundary constraints for new dimensions
				const { constrainedX, constrainedY } = this.applyBoundaryConstraints(newLeft, newTop, newWidth, newHeight, canvasRect);

				getElement.x_loc = constrainedX;
				getElement.y_loc = constrainedY;
				getElement.width = Math.max(newWidth, 20);  // Minimum width
				getElement.height = ((elementType === 'textbox') && (newHeight < 12)) ? 12 : Math.max(newHeight, 12); // Minimum height

				// Update font size based on element type
				if (elementType === 'textbox') {
					getElement.fontSize = this.calculateFontSize(getElement.width, getElement.height, 'textBox');
					this.allTextBoxes = this.allTextBoxes.map(tb =>
						tb.id === getElement.id ? { ...getElement } : tb
					);
				} else if (elementType === 'dropdown') {
					getElement.fontSize = this.calculateFontSize(getElement.width, getElement.height, 'dropDown');
					this.allDropDowns = this.allDropDowns.map(ddw =>
						ddw.id === getElement.id ? { ...getElement } : ddw
					);
				} else if (elementType === 'image') {
					this.allImages = this.allImages.map(im =>
						im.id === getElement.id ? { ...getElement } : im
					);
				} else if (elementType === 'signature') {
					this.allSignatures = this.allSignatures.map(sb =>
						sb.id === getElement.id ? { ...getElement } : sb
					);
				} else if (elementType === 'checkbox') {
					this.allCheckBoxes = this.allCheckBoxes.map(cb =>
						cb.id === getElement.id ? { ...getElement } : cb
					);
				}
				this.updateElementPosition(getElement, elementType);
			}
		},
		resizeMouseUp(getElement, event) {
			event.preventDefault();
			if (!getElement.isResizing) return;
			getElement.isResizing = false;

			// Remove event listeners using stored handlers
			document.removeEventListener('mousemove', this.currentResizeMouseMoveHandler);
			document.removeEventListener('mouseup', this.currentResizeMouseUpHandler);

			// Clear stored handlers
			this.currentResizeMouseMoveHandler = null;
			this.currentResizeMouseUpHandler = null;
		},
		updateElementPosition(getElement, elementType) {
			let refName = undefined;
			switch (elementType) {
				case 'textbox':
					refName = `textBox-${getElement.id}`;
					break;
				case 'dropdown':
					refName = `dropDown-${getElement.id}`;
					break;
				case 'image':
					refName = `imageBox-${getElement.id}`;
					break;
				case 'signature':
					refName = `signatureBox-${getElement.id}`;
					break;
				case 'checkbox':
					refName = `checkBox-${getElement.id}`;
					break;

				default:
					break;
			}
			const ref = this.$refs[refName];
			const element = ref?.$el || ref;
			const resolvedElement = Array.isArray(element) ? element[0] : element;
			if (resolvedElement && resolvedElement.style) {
				this.$nextTick(() => {
					try {
						resolvedElement.style.left = `${getElement.x_loc}px`;
						resolvedElement.style.top = `${getElement.y_loc}px`;
						resolvedElement.style.width = `${getElement.width}px`;
						resolvedElement.style.height = `${getElement.height}px`;
						resolvedElement.style.fontSize = `${getElement.fontSize}px`;
					} catch (e) {
						console.error(`Failed to set styles for element:`, e);
					}
				});
			} else {
				console.error(
					`Ref for ${refName} - ${getElement.id} is not a valid DOM element or lacks a style property.`,
					resolvedElement
				);
			}
		},
		tagSearch() {
			const searchTerm = this.selectedTextBox.database_tag.toLowerCase().trim();

			if (searchTerm) {
				this.filteredTags = this.databaseTags.filter(tag =>
					tag.label.toLowerCase().includes(searchTerm) ||
					tag.category.toLowerCase().includes(searchTerm)
				);
			} else {
				this.filteredTags = [];
				this.selectedTextBox.isPlainTextReadOnly = false;
			}
		},
		async selectTag(tag) {
			this.filteredTags = [];

			// Set the database tag based on the selected tag's category and label
			this.selectedTextBox.database_tag = `${tag.category} | ${tag.label}`;
			const textBox = this.allTextBoxes.find(tb => tb.id === this.selectedElementId);
			this.wrappedTextBoxVal[textBox.id] = this.selectedTextBox.database_tag;
			this.selectedTextBox.isDatabaseTagReadOnly = false;
			this.selectedTextBox.isPlainTextReadOnly = true;
		},
		toggleDropdown(id) {
			this.allDropDowns.forEach(dropDown => {
				if (dropDown.id === id) {
					dropDown.isOpen = !dropDown.isOpen;
				} else {
					dropDown.isOpen = false;
				}
			});
		},
		// wrapText(text, maxWidth, fontSize) {
		// 	const words = text.split(' ');
		// 	let lines = [];
		// 	let currentLine = words[0];

		// 	for (let i = 1; i < words.length; i++) {
		// 		const word = words[i];
		// 		const width = this.calculateTextWidth(`${currentLine} ${word}`, fontSize);

		// 		console.log('width < maxWidth:- ', width, maxWidth, width < maxWidth);
		// 		if (width < maxWidth) {
		// 			currentLine += ` ${word}`;
		// 		} else {
		// 			lines.push(currentLine);
		// 			currentLine = word;
		// 		}
		// 	}

		// 	lines.push(currentLine);
		// 	console.log('lines:- ', lines);
		// 	return lines.join('\n');
		// },
		async wrapText(text, maxWidth, maxHeight, initialFontSize) {
			let lines = [];
			let currentLine = '';
			let fontSize = initialFontSize;
			const minFontSize = this.minFontSize;
			let ellipsisAdded = false;

			// Helper function to test if the current text fits the box
			const fitsWithinBounds = (testLines) => {
				const totalHeight = testLines.reduce((height, line) => {
					const { height: lineHeight } = this.getTextDimensions(line, 1, fontSize);
					return height + lineHeight; // Accumulate height for each line
				}, 0);

				// Get width of the last line only
				const { width } = this.getTextDimensions(testLines[testLines.length - 1], 1, fontSize);

				return width <= maxWidth && totalHeight <= maxHeight;
			};

			const truncateTextWithEllipsis = (line) => {
				let truncatedLine = line; 
				while (this.getTextDimensions(truncatedLine + "...", 1, fontSize).width > maxWidth) {
					truncatedLine = truncatedLine.slice(0, -1); // Remove last character until it fits
				}
				return truncatedLine + "..."; // Add ellipsis to last line
			};

			// Check if the string has spaces
			if (text.includes(' ')) {
				const words = text.split(' ');

				for (let i = 0; i < words.length; i++) {
					const word = words[i];
					const testLine = currentLine ? `${currentLine} ${word}` : word;

					// Check width for the test line
					if (!fitsWithinBounds([...lines, testLine])) {
						// If the line is too wide, push the current line to lines array
						if (currentLine) {
							lines.push(currentLine);
							currentLine = word; // Start a new line with the current word
						} else {
							currentLine = word; // Start a new line with the current word
						}
					} else {
						currentLine = testLine; // Update the current line
					}

					// Check height for the current lines
					if (!fitsWithinBounds(lines.concat(currentLine))) {
						fontSize -= 1.1; // Decrease the font size if height exceeds

						if (fontSize < minFontSize) {
							fontSize = minFontSize; // If font size goes below limit, set to minFontSize
							if (currentLine) {
								lines.push(truncateTextWithEllipsis(currentLine)); // Truncate the last line with ellipsis
								ellipsisAdded = true;
							}
							break; // Stop processing further text
						}
					}
				}
			} else {
				// If no spaces, handle character wrapping
				for (let i = 0; i < text.length; i++) {
					const char = text[i];
					const testLine = currentLine ? `${currentLine}${char}` : char;

					// Check width for the test line
					if (!fitsWithinBounds([...lines, testLine])) {
						// If the line is too wide, push the current line to lines array
						if (currentLine) {
							lines.push(currentLine);
							currentLine = char; // Start a new line with the current character
						} else {
							currentLine = char; // Start a new line with the current character
						}
					} else {
						currentLine = testLine; // Update the current line
					}

					// Check height for the current lines
					if (!fitsWithinBounds(lines.concat(currentLine))) {
						fontSize -= 1.1; // Decrease the font size if height exceeds

						if (fontSize < minFontSize) {
							fontSize = minFontSize; // If font size goes below limit, set to minFontSize
							if (currentLine) {
								lines.push(truncateTextWithEllipsis(currentLine)); // Truncate the last line with ellipsis
								ellipsisAdded = true;
							}
							break; // Stop processing further text
						}
					}
				}
			}

			if (!ellipsisAdded && currentLine) {
				lines.push(currentLine);
			}
		
			return {
				fontSize: fontSize,
				wrappedText: lines.join('\n')
			};
		},
		getTextDimensions(text, numberOfLines, fontSize) {
			const span = $("<span>");
			span.text(text);
			span.css({
				"fontSize": fontSize + "px",
				"position": "absolute",
				"visibility": "hidden",
				"white-space": "nowrap",
				"display": "inline-block"
			});

			$("body").append(span);

			const width = span.width() * 1.2 ; // 1.2 scaling factor for width
			const height = span.height() * numberOfLines; // Height multiplied by number of lines

			span.remove();

			return { width, height };
		},
		async uploadDocument() {
			this.isShowLoader = true;
			await this.savePdf();

			await this.v$.$validate();

			if (this.v$.$error) {
				this.isShowLoader = false;

				Object.keys(this.v$).forEach((field) => {
					if (this.v$[field]?.$errors) {
						this.v$[field].$errors.forEach((error) => {
							console.log(`Field: ${field}, Error: ${error.$message}`);
						});
					}
				});

				return false;
			}

			// Proceed with form submission if there are no errors
			let $this = this;
			const formData = new FormData();
			const pdfBlob = new Blob([this.existingPdfBytes], { type: 'application/pdf' });


			if (pdfBlob) {
				formData.append('pdfData', pdfBlob, 'annotated_file.pdf');
			}

			formData.append('id', this.$route.params.template_id);
			formData.append('title', this.form.title);
			formData.append('additional_info', this.form.additional_info);
			formData.append('module', this.form.module);
			formData.append('annotation', this.form.annotations);

			// console.log('FormData Payload:');
			// formData.forEach((value, key) => {
			// 	console.log(`${key}:`, value);
			// });

			try {
				const config = {
					headers: {
						'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
						'Content-Type': 'multipart/form-data'
					},
				};
				const response = await axios.post('e-forms/upload', formData, config);

				if (response.status === 200) {
					this.isShowLoader = false;
					$this.$filters.moshaToast(response.data.message, "success");
					if ($this.$route.name !== 'templateMasterEdit') {
						$this.$router.push({ name: 'patientTemplateList', params: { patient_id: $this.$route.params.patient_id, module: $this.form.module.toLowerCase() } });
					} else {
						$this.$router.push({ name: 'templateMasterList' });
					}
				}
			} catch (error) {
				console.error('Error saving PDF with notes on the server:', error);
			}
		},
		async savePdf() {
			try {
				this.existingPdfBytes = await this.pdfDoc.save();
				const pdfLibDoc = await PDFDocument.load(this.existingPdfBytes);
				// const pages = pdfLibDoc.getPages();

				const annotations = {};
				// const canvas = this.$refs.pdfCanvas;
				// const canvasRect = canvas.getBoundingClientRect();

				if (!this.validateAll()) {
					this.isShowLoader = false;
					return;
				}

				// Handle text-box
				if (this.allTextBoxes.length) {
					this.allTextBoxes.forEach((textBox, index) => {
						
						const refName = `textBox-${textBox.id}`;
						const ref = this.$refs[refName];
						const element = ref?.$el || ref;
						const textBoxElement = Array.isArray(element) ? element[0] : element;
						
						if (textBoxElement) {

							annotations[`textBox${index}`] = {
								field_type: textBox.text_box_name,
								database_type: 'string',
								database_tag: textBox.database_tag || '',
								placeholder: textBox.placeholder || '',
								x_loc: textBox.x_loc,
								y_loc: textBox.y_loc,
								height: textBox.height,
								width: textBox.width,
								pageNum: textBox.onPage,
								fontSize: textBox.fontSize,
							};

							console.log(`TextBox Annotation for index ${index}:`, annotations[`textBox${index}`]);
						} else {
							console.error(`Ref for TextBox ${index} not found.`);
						}
					});
				}

				// Handle dropdown
				if (this.allDropDowns.length) {
					this.allDropDowns.forEach((dropDown, index) => {

						const refName = `dropDown-${dropDown.id}`;
						const ref = this.$refs[refName];
						const element = ref?.$el || ref;
						const dropDownElement = Array.isArray(element) ? element[0] : element;

						if (dropDownElement) {

							annotations[`dropDown${index}`] = {
								field_type: dropDown.dropdown_box_name,
								database_type: 'array',
								database_tag: dropDown.database_tag,
								placeholder: dropDown.placeholder,
								x_loc: dropDown.x_loc,
								y_loc: dropDown.y_loc,
								width: dropDown.width,
								height: dropDown.height,
								pageNum: dropDown.onPage,
								fontSize: dropDown.fontSize,
							};
							console.log(`DropDown Annotation for index ${index}:`, annotations[`dropDown${index}`]);
						} else {
							console.error(`Ref for DropDown ${index} not found.`);
						}
					});
				}

				// Handle image
				if (this.allImages.length) {
					this.allImages.forEach(async (imageBox, index) => {
						if (imageBox.placeholder) {

							const refName = `imageBox-${imageBox.id}`;
							const ref = this.$refs[refName];
							const element = ref?.$el || ref;
							const imageBoxElement = Array.isArray(element) ? element[0] : element;

							if (imageBoxElement) {
								try {
									annotations[`imageBox${index}`] = {
										field_type: 'image',
										database_type: 'string',
										database_tag: '',
										placeholder: imageBox.placeholder,
										x_loc: imageBox.x_loc,
										y_loc: imageBox.y_loc,
										width: imageBox.width,
										height: imageBox.height,
										pageNum: imageBox.onPage,
									};
									console.log(`Image Annotation for index ${index}:`, annotations[`imageBox${index}`]);
								} catch (error) {
									console.error(`Error processing imageBox ${index}:`, error);
								}
							} else {
								console.error(`Element for ImageBox ${index} not found.`);
							}
						} else {
							console.error(`Invalid src or onPage value for ImageBox ${index}`);
						}
					});
				}

				// Handle signature
				if (this.allSignatures.length) {
					this.allSignatures.forEach(async (signatureBox, index) => {
						
						const refName = `signatureBox-${signatureBox.id}`;
						const ref = this.$refs[refName];
						const element = ref?.$el || ref;
						const signatureBoxElement = Array.isArray(element) ? element[0] : element;

						if(signatureBoxElement) {

							annotations[`signatureBox${index}`] = {
								field_type: 'signature',
								database_type: 'string',
								database_tag: localStorage.getItem('userId'),
								placeholder: 'user_id',
								x_loc: signatureBox.x_loc,
								y_loc: signatureBox.y_loc,
								width: signatureBox.width,
								height: signatureBox.height,
								pageNum: signatureBox.onPage,
							};
							console.log(`Signature Annotation for index ${index}:`, annotations[`signatureBox${index}`]);
						} else {
							console.error(`Element for SignatureBox ${index} not found.`);
						}
					});
				}

				// Handle checkbox	
				if (this.allCheckBoxes.length) {
					this.allCheckBoxes.forEach(async (checkBox, index) => {

						const refName = `checkBox-${checkBox.id}`;
						const ref = this.$refs[refName];
						const element = ref?.$el || ref;
						const checkBoxElement = Array.isArray(element) ? element[0] : element;

						if (checkBoxElement) {

							annotations[`checkBox${index}`] = {
								field_type: 'checkbox',
								database_type: 'boolean',
								database_tag: '',
								placeholder: false,
								x_loc: checkBox.x_loc,
								y_loc: checkBox.y_loc,
								width: checkBox.width,
								height: checkBox.height,
								pageNum: checkBox.onPage,
							};
							console.log(`checkbox Annotation for index ${index}:`, annotations[`checkBox${index}`]);
						} else {
							console.error(`Ref for checkBox ${index} not found.`);
						}
					});
				}

				// Saving the final annotated PDF
				const annotatedPdfBytes = await pdfLibDoc.save();
				this.form.annotatedPdfBytes = annotatedPdfBytes;

				// Trigger PDF download
				// this.downloadPdf(annotatedPdfBytes);

				// this.downloadJson(annotations);
				this.form.annotations = JSON.stringify(annotations, null, 2);

			} catch (error) {
				console.error("Error saving PDF:", error);
			}
		},
		downloadPdf(pdfBytes) {
			const blob = new Blob([pdfBytes], { type: 'application/pdf' });
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'modified_pdf.pdf';
			link.click();
		},
		downloadJson(annotations) {
			const formattedJson = JSON.stringify(annotations, null, 2); // Formatting JSON with indentation
			const blob = new Blob([formattedJson], { type: 'application/json' });
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'annotations.json';
			link.click();
		},
		backToList() {
			if (this.$route.name !== 'templateMasterEdit') {
				this.$router.push({ name: 'patientTemplateList', params: { patient_id: this.$route.params.patient_id, module: this.form.module.toLowerCase() } });
			} else {
				this.$router.push({ name: 'templateMasterList', params: {} });
			}
		},
		beforeTooltipRender(args) {
			if (args.element.classList.contains('e-pv-annotation-icon')) {
				args.content = 'Custom tooltip content for annotation icon';
			} else if (args.element.classList.contains('e-pv-bookmark-icon')) {
				args.content = 'Custom tooltip content for bookmark icon';
			} else {
				args.content = 'Default tooltip content';
			}
		},
		// initializePdf() {
		// 	if (window.pdfjsLib) {
		// 		window.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
		// 	}
		// },
		// onDocumentLoad: function () {
			// let viewer = this.$refs.pdfviewer.ej2Instances;
			// $.each(this.dynamicFields, function(key, detail) {
			// 	console.log(key)
			// 	viewer.formDesignerModule.addFormField(detail.type, detail);
			// });
			// viewer.formDesignerModule.updateFormField(viewer.formFieldCollections[0], {  });
		// },
	},
	mounted() {
		const pdfScript = document.createElement('script');
		pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.min.js';
		// Letest
		// pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.min.js';
		// client
		// pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.min.js';

		pdfScript.onload = () => {
			const workerScript = document.createElement('script');
			workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
			// Letest
			// workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.worker.min.js';
			// client
			// workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf-lib/1.17.1/pdf-lib.min.js';

			workerScript.onload = () => {
				// Initialize your PDF logic here after both scripts are loaded
				this.edit();  // Call this.edit() after both scripts are fully loaded
			};

			document.head.appendChild(workerScript);
		};

		document.head.appendChild(pdfScript);
		document.addEventListener('keydown', this.handleKeydown);
	},
	unmounted() {
		document.removeEventListener('keydown', this.handleKeydown);
	},
	watch: {
		'selectedTextBox.text_box_name'(newValue) {
			if (!this.selectedTextBox) return;
			if (newValue) {
				this.selectedTextBox.text_box_name = newValue;
			}
		},
		'selectedTextBox.placeholder'(newValue) {
			if (this.selectedTextBox && newValue) {
				this.selectedTextBox.isDatabaseTagReadOnly = !!newValue;
				this.selectedTextBox.isPlainTextReadOnly = false;

				this.selectedTextBox.placeholder = newValue;
				this.wrapText(newValue, this.selectedTextBox.width, this.selectedTextBox.height, this.selectedTextBox.fontSize)
					.then(result => {
						const textBox = this.allTextBoxes.find(tb => tb.id === this.selectedElementId);

						if (textBox) {
							textBox.wrap_text_content = result.wrappedText;
							textBox.fontSize = result.fontSize;


							this.wrappedTextBoxVal[textBox.id] = result.wrappedText;
						}
					})
					.catch(error => {
						console.error('Error in wrapText:', error);
					})
			} else {
				this.selectedTextBox.isDatabaseTagReadOnly = false;
			}
		},
		'selectedDropDown.dropdown_box_name'(newValue) {
			if (!this.selectedDropDown) return;
			if (newValue) {
				this.selectedDropDown.dropdown_box_name = newValue;
				const dropdownIndex = this.allDropDowns.findIndex(
					(dropdown) => dropdown.id === this.selectedDropDownId
				);
				if (dropdownIndex !== -1) {
					this.allDropDowns[dropdownIndex].dropdown_box_name = newValue;
				}
			} else {
				const dropdownIndex = this.allDropDowns.findIndex(
					(dropdown) => dropdown.id === this.selectedDropDownId
				);
				if (dropdownIndex !== -1) {
					this.allDropDowns[dropdownIndex].dropdown_box_name = '';
				}
			}
		},
		'selectedDropDown.placeholder'(newValue) {
			if (this.selectedDropDownId !== null) {
				const options = newValue.split(',').map((option) => {
					const trimmedOption = option.trim();
					return trimmedOption;
				});

				const dropdownIndex = this.allDropDowns.findIndex(
					(dropdown) => dropdown.id === this.selectedDropDownId
				);
				if (dropdownIndex !== -1) {
					this.allDropDowns[dropdownIndex].database_tag = options;
					this.allDropDowns[dropdownIndex].placeholder = newValue;
				}
			}
		}
	},
	// provide: {
		// PdfViewer: [Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormDesigner, FormFields]
	// }
}
</script>
<style scoped>

#pdfViewer_fileUploadElement {
	display: none !important;
}

.e-checkbox-wrapper {
	display: none !important;
}

.pdf_search_section {
	position: absolute;
	top: 31px;
	width: 100%;
	background: #f5f5f5;
	padding: 10px;
	box-shadow: 0px 0px 6px 1px #e1e1e1;
	border-radius: 2px;
	border-top: none;
}

.pdf_search {
	padding-left: 0;
	margin-bottom: -10px;
	margin-top: -7px;
}

.pdf_search_option {
	list-style-type: none;
	line-height: normal;
	border-bottom: 1px solid #e7e4e4;
	padding: 10px;
	cursor: pointer;
}

.pdf_search_option:hover {
	background: #e1e1e1;
}

.text-box {
	position: absolute;
	border: 1px dashed #000;
	background-color: rgba(255, 255, 255, 0.8);
	box-sizing: border-box;
	z-index: 1;
}

/* Styles for the text area */
.text-box-content {
	resize: none;
	width: 100%;
	height: 100%;
	border: 0px solid #0e2641;
	/* padding: 5px; */
	box-sizing: border-box;
	overflow: hidden;
	line-height: normal;
	box-shadow: none;
	outline: none;position:relative;color:#0E2641;
	/* border-radius: 5px; */
}

/* Styles for the resize handle */
.resize-handle {
	position: absolute;
	width: 11px;
	height: 11px;
	background: rgba(0, 0, 0, 0.5);
	cursor: nwse-resize;
}

.resize-handle.top-left {
	top: -5px;
	left: -5px;
	cursor: nw-resize;
}

.resize-handle.top-right {
	top: -5px;
	right: -5px;
	cursor: ne-resize;
}

.resize-handle.bottom-left {
	bottom: -5px;
	left: -5px;
	cursor: sw-resize;
}

.resize-handle.bottom-right {
	bottom: -5px;
	right: -5px;
	cursor: se-resize;
}

.pdf-dropdown-drag {
	position: absolute;
	z-index: 1;
	border: 1px dashed #000;
	/*	padding: 5px;*/
	background-color: rgba(255, 255, 255, 0.8);
	box-sizing: border-box;
	z-index: 1;
}

.dragHandle {
	position: absolute;
	/* width: 100%; */
	height: 5px;
	background: rgba(0, 0, 0, 0.3);
	cursor: move;
	top: -6px !important;
	left: 12px;
	right: 12px;
}

/* .resize-handle {
	position: absolute;
	width: 11px;
	height: 11pxpx;
	background: rgba(0, 0, 0, 0.5);
	cursor: nwse-resize;
} */

/* .resize-handle.bottom-right {
	bottom: -6px;
	right: -6px;
} */

#pdfCanvas {
	/*	border : 1px solid black;*/
}

.image-box,
.signature-box {
	position: absolute;
	border: 1px dashed #000;
	padding: 0;
	background-color: rgba(255, 255, 255, 0.8);
	display: none;
	box-sizing: border-box;
	z-index: 1;
}
.image-box img{background: #fff;position: relative;}
.drag-handle {
	width: 10px;
	height: 10px;
	background-color: red;
	position: absolute;
	right: 0;
	bottom: 0;
	cursor: move;
}

.draggable-box {
	position: absolute;
	display: none;
	width: 250px;
	height: 250px;
	cursor: move;
}

.signature-image {
	display: block;
}

.checkbox-box {
	position: absolute;
	border: 1px dashed #000;
	background-color: rgba(255, 255, 255, 0.8);
	/* display: none; */
	/* box-sizing: border-box;z-index: 1; */
}

.checkbox-box input[type="checkbox"] {
	width: 25px;
	height: 25px;
	cursor: pointer;
}


/* Container for buttons */
.div-button-container {}

/* Style for clickable span elements */
.div-button-container span {
	transition: background-color 0.3s, color 0.3s;
}

/* Style for 'Add Blank Page' button */
.div-button-container .add-page {
	color: #0E2641;
	font-size: calc(var(--scale-ratio) * 18px);
	font-weight: 600;
	line-height: normal;
	text-decoration-line: underline;
	cursor: pointer;
	margin-right: calc(var(--scale-ratio) * 35px);
	text-underline-offset: 4px;
	text-decoration-thickness: 1px;
}


/* Style for 'Delete Element' button */
.div-button-container .delete-element {
	color: #CC0000;
	font-size: calc(var(--scale-ratio) * 18px);
	font-weight: 600;
	line-height: normal;
	text-decoration-line: underline;
	cursor: pointer;
	margin-right: calc(var(--scale-ratio) * 35px);
	text-underline-offset: 4px;
	text-decoration-thickness: 1px;
}

.heading_selact_drodwn {
	width: 200px;
	/* Adjust as needed */
	height: auto;
	/* Adjust as needed */
	position: absolute;
	cursor: move;
}

.drop-container {
	position: relative;
	padding: 10px;
	border-radius: 4px;
	background-color: #f9f9f9;
	width: 250px;
}

.dropdown-select {
	width: 100%;
	font-size: 14px;
}

/*------dropDown-------*/

.name-selcet-w-pdf{background: #fff;}
.name-selcet-w-pdf .select-options {
	padding: 0;
	margin: 0;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
	border-radius: 0 0 5px 5px;
	position: absolute;
	width: 100%;
}

.name-selcet-w-pdf .custom-select .select-display {
	color: #0E2641;
	padding: 7px 12px;
	border-radius: 5px;
	line-height: normal;
	cursor: pointer;
}

/*.name-selcet-w-pdf .custom-select .select-display .selected-list-actv{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 50px;display: inline-block;vertical-align: middle;}*/
.name-selcet-w-pdf .custom-select .select-display img {
	margin-left: 6px;
	width: 25px;
}

.name-selcet-w-pdf .select-options li {
	display: block;
	border-bottom: 0.5px solid #ECE3D1;
	color: #0E2641;
	font-size: 16px;
	padding: 7px 12px;
	line-height: normal;
	cursor: pointer;
	text-align: center;
}

.context-menu {position: absolute;
  background-color: #f6f6f7;
  border: 1px solid #ccc;
  padding: 0px;
  z-index: 1000;
  box-shadow: 0 1px 13px rgba(0, 0, 0, 0.1);
}

.context-menu ul {list-style: none;margin: 0;padding: 0;}
.context-menu li {padding: 2px 20px;cursor: pointer;font-size: 14px;border-bottom: 1px solid #dcdcdc;}
.context-menu li:last-child{border-bottom: 0;}
.context-menu li:hover {background-color: #ece3d180;}

</style>